var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Email" } },
        [
          _c("validation-provider", {
            staticClass: "align-items-center",
            attrs: { name: "Email", rules: "required|email" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("b-form-input", {
                          staticClass: "mr-1",
                          attrs: { placeholder: "", required: "" },
                          model: {
                            value: _vm.emailData.emailAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailData, "emailAddress", $$v)
                            },
                            expression: "emailData.emailAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center" },
            [
              _c(
                "b-form-group",
                { staticClass: "mr-1", attrs: { label: "Phone" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      name: "Phone",
                      rules: "required|min:14|regex:^[0-9() -]+$",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["(###) ###-####"],
                                  expression: "['(###) ###-####']",
                                },
                              ],
                              attrs: {
                                placeholder: "",
                                maxlength: "14",
                                type: "text",
                                required: "",
                              },
                              model: {
                                value: _vm.phoneData.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.phoneData, "number", $$v)
                                },
                                expression: "phoneData.number",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                _vm._s(
                                  errors[0] ? errors[0].replace("14", "10") : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "Type" } },
                [
                  _c("validation-provider", {
                    staticClass: "align-items-center",
                    attrs: { name: "Type", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    options: Object.values(_vm.PhoneType).map(
                                      function (x) {
                                        return { text: x, value: x }
                                      }
                                    ),
                                    "text-field": "text",
                                    "value-field": "value",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.phoneData.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.phoneData, "type", $$v)
                                    },
                                    expression: "phoneData.type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }